import React, { useState, useEffect } from "react"

import { Link } from "gatsby"

import Map from "../components/Map"

import Layout from "../components/layout"
import SEO from "../components/seo"

import fetchJsonp from "fetch-jsonp"

import "url-search-params-polyfill"

import "../components/leaflet.css"

const base64url = require("base64url")

var isBase64 = require("is-base64")

require("es6-promise").polyfill()

var Scroll = require("react-scroll")
var scroller = Scroll.scroller

function calculateSalary(
  Currency,
  SalaryFrom,
  SalaryTo,
  SalaryType,
  SalaryPeriod
) {
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  let NewSalary
  let NewSalaryFrom
  let NewSalaryTo

  let currencyFormatted = Currency

  if (Currency === "&pound;") {
    currencyFormatted = "£"
  }

  if (SalaryTo === 0) {
    NewSalary = SalaryType
  } else if (SalaryFrom === SalaryTo) {
    if (SalaryFrom >= 1000) {
      // Remove decimals
      NewSalaryFrom = currencyFormatted + numberWithCommas(SalaryFrom.toFixed())
    } else {
      // Add two decimals
      NewSalaryFrom =
        currencyFormatted + numberWithCommas(SalaryFrom.toFixed(2))
    }
    NewSalary = NewSalaryFrom + " " + SalaryPeriod
  } else {
    if (SalaryFrom >= 1000) {
      NewSalaryFrom = currencyFormatted + numberWithCommas(SalaryFrom.toFixed())
    } else {
      NewSalaryFrom =
        currencyFormatted + numberWithCommas(SalaryFrom.toFixed(2))
    }
    if (SalaryTo >= 1000) {
      NewSalaryTo = currencyFormatted + numberWithCommas(SalaryTo.toFixed())
    } else {
      NewSalaryTo = currencyFormatted + numberWithCommas(SalaryTo.toFixed(2))
    }

    NewSalary = NewSalaryFrom + " - " + NewSalaryTo + " " + SalaryPeriod
  }
  return NewSalary
}

function CheckJobHasNotExpired(JSONDate) {
  if (JSONDate) {
    let expDate = new Date(parseInt(JSONDate.substr(6), 10))
    let now = new Date()

    if (expDate > now) {
      return true
    }

    return false
  } else {
    return false
  }
}

function FormatDateTime(JSONDate) {
  if (JSONDate) {
    // Set default values to avoid function breaking
    var expDate = new Date(parseInt(JSONDate.substr(6)))
    var dateFormatOptions = new Object({})
    dateFormatOptions.weekday = "long"
    dateFormatOptions.year = "numeric"
    dateFormatOptions.month = "short"
    dateFormatOptions.day = "numeric"
    return expDate.toLocaleDateString(undefined, dateFormatOptions)
  } else {
    return JSONDate
  }
}

function generateGoogleJobsSchema(vacancy) {
  return {
    "@context": "http://schema.org",
    "@type": "JobPosting",
    baseSalary: calculateSalary(
      vacancy.Currency,
      vacancy.SalaryFrom,
      vacancy.SalaryTo,
      vacancy.SalaryType,
      vacancy.SalaryPeriod
    ),
    jobBenefits: vacancy.Benefits,
    datePosted: FormatDateTime(vacancy.PostingDate),
    validThrough: FormatDateTime(vacancy.ExpiryDate),
    description: vacancy.Description,
    employmentType: vacancy.JobTypeTime,
    industry: vacancy.Industry,
    jobLocation: {
      "@type": "Place",
      geo: {
        longitude: vacancy.Longitude,
        latitude: vacancy.Latitude,
        "@type": "GeoCoordinates",
      },
      address: {
        "@type": "PostalAddress",
        addressCountry: vacancy.Country,
        addressLocality: vacancy.Location,
        addressRegion: vacancy.Region,
        postalCode: vacancy.PostCode,
      },
    },
    occupationalCategory: vacancy.JobCategory,
    salaryCurrency: vacancy.Currency === "&pound;" ? "GBP" : "EUR",
    hiringOrganization: {
      "@type": "Organization",
      name: vacancy.Company,
      logo: vacancy.CompanyLogoPath,
    },
    title: vacancy.JobTitle,
    workHours: vacancy.JobTypeTime,
  }
}

const listenForIFrameHeight = function listenForIFrameHeight() {
  if (typeof window !== "undefined") {
    window.addEventListener(
      "message",
      function(event) {
        let careersPageiFrame = null

        if (document.getElementById("vf_iFrame_application_form")) {
          careersPageiFrame = document.getElementById(
            "vf_iFrame_application_form"
          )
        }

        if (
          careersPageiFrame !== null &&
          careersPageiFrame !== undefined &&
          careersPageiFrame !== "undefined"
        ) {
          let careersPageEvent = event.data[0]
          let careersPageHeight = event.data[1]

          switch (careersPageEvent) {
            case "setCareersPageHeight":
              let careersPageHeightExtra = careersPageHeight + 50

              careersPageiFrame.height = careersPageHeightExtra
              break
          }
        }
      },
      false
    )
  }
}

const SmartfeedIndividual = props => {
  const id = props["*"]

  const [vacancy, setVacancy] = useState([])
  const [showApplication, setshowApplication] = useState(false)
  const [vacancyTitle, setVacancyTitle] = useState("Loading vacancy....")
  const [loading, setLoading] = useState(false)
  const [loadingText, setLoadingText] = useState("")

  useEffect(() => {
    listenForIFrameHeight()
    setshowApplication(false)

    setTimeout(function() {
      setLoadingText(
        <span>
          If this is taking too long, please{" "}
          <Link to="/">go back to the search page</Link> and re-select a
          vacancy.
        </span>
      )
    }, 3000)

    const timeOut = setTimeout(() => {
      fetchVacancy(id)
    }, 25)
    return () => clearTimeout(timeOut)
  }, [id])

  function vfScrollToElement(element, to, duration) {
    if (duration <= 0) return
    var difference = to - element.scrollTop
    var perTick = (difference / duration) * 10

    setTimeout(function() {
      element.scrollTop = element.scrollTop + perTick
      if (element.scrollTop === to) return
      window.scrollTo(element, to, duration - 10)
    }, 10)
  }

  function animate(elem, style, unit, from, to, time, prop) {
    if (!elem) {
      return
    }
    var start = new Date().getTime(),
      timer = setInterval(function() {
        var step = Math.min(1, (new Date().getTime() - start) / time)
        if (prop) {
          elem[style] = from + step * (to - from) + unit
        } else {
          elem.style[style] = from + step * (to - from) + unit
        }
        if (step === 1) {
          clearInterval(timer)
        }
      }, 25)
    if (prop) {
      elem[style] = from + unit
    } else {
      elem.style[style] = from + unit
    }
  }

  function activateApplicationForm(setting) {
    setshowApplication(setting)

    scroller.scrollTo("ApplicationScrollTarget", {
      duration: 500,
      delay: 0,
      smooth: true,
      offset: -150,
    })
  }

  const fetchVacancy = async id => {
    setLoading(true)
    try {
      fetchJsonp("https://sf.vacancy-filler.co.uk/CareerPage/GetItem?id=" + id)
        .then(function(response) {
          return response.json()
        })
        .then(function(json) {
          const responseVacancy = json
          const resultVacancy = responseVacancy
          setLoading(false)
          setVacancy(resultVacancy)
          setVacancyTitle(resultVacancy.JobTitle)
          return resultVacancy
        })
        .catch(function(ex) {
          return fetchJsonp(
            `https://sf.vacancy-filler.co.uk/CareerPage/GetItem?id=${new Buffer(
              id,
              "base64"
            ).toString()}`
          )
            .then(function(response) {
              return response.json()
            })
            .then(function(json) {
              const responseVacancy = json
              const resultVacancy = responseVacancy
              setLoading(false)
              setVacancy(resultVacancy)
              setVacancyTitle(resultVacancy.JobTitle)
              return resultVacancy
            })
            .catch(function(ex) {
              return fetchJsonp(
                `https://sf.vacancy-filler.co.uk/CareerPage/GetItem?id=${new Buffer(
                  id,
                  "base64"
                ).toString()}`
              )
                .then(function(response) {
                  return response.json()
                })
                .then(function(json) {
                  const responseVacancy = json
                  const resultVacancy = responseVacancy
                  setLoading(false)
                  setVacancy(resultVacancy)
                  setVacancyTitle(resultVacancy.JobTitle)
                  return resultVacancy
                })
                .catch(function(ex) {
                  setLoading(false)
                  console.error(ex, "error")
                })
            })
        })

      /*
     JSON method, sadly doesn't work with SF
     const fetchingVacancy = await fetch(
        `https://sf.vacancy-filler.co.uk/CareerPage/GetItem?callback=gatsby&id=${id}`
      )
      const responseVacancy = await fetchingVacancy.json()
      const resultVacancy = await responseVacancy
      setLoading(false)
      setVacancy(resultVacancy)
      return resultVacancy
      */
    } catch (error) {
      setLoading(false)
      console.error(error.message, "error")
    }
  }

  return (
    <Layout>
      <div
        className="main-banner"
        style={
          !loading && vacancy && vacancy != [] && vacancy.Description
            ? {
                backgroundImage: `url("${
                  vacancy.Region === "The Yorkshire Estate"
                    ? "https://cdn2.accropress.com/content-uploads/3d6c7a45-b41d-47fd-8bff-01ee8463584e/11c8829b-7856-43f6-b4ff-fa586f6e6ee6/3jbdk6cgSJGuzbJzgTSP_yorkshire_estate_main.jpg"
                    : vacancy.Region === "The Irish Estate"
                    ? "https://cdn2.accropress.com/content-uploads/3d6c7a45-b41d-47fd-8bff-01ee8463584e/cffb41e5-7861-4121-8596-8c377fbfc0cc/rJq4IyVWR5meWqZcSFvh_irish_estate_main.jpg"
                    : vacancy.Region === "Devonshire Hotel & Restaurant Group"
                    ? "https://cdn2.accropress.com/content-uploads/3d6c7a45-b41d-47fd-8bff-01ee8463584e/a4dfda63-ad2d-4c93-9429-82dd7fd39036/vn4BJ3F5QTirM2lUbK0l_hotels-and-restaurants.jpg"
                    : vacancy.Region === "The Derbyshire Estate"
                    ? "https://cdn2.accropress.com/content-uploads/3d6c7a45-b41d-47fd-8bff-01ee8463584e/7cef709d-00c2-4085-9087-92f80310d852/R0lx788T7qDx176kMo2H_derbyshire_estate_main.jpg"
                    : "https://cdn2.accropress.com/content-uploads/3d6c7a45-b41d-47fd-8bff-01ee8463584e/7cef709d-00c2-4085-9087-92f80310d852/R0lx788T7qDx176kMo2H_derbyshire_estate_main.jpg"
                }")`,
              }
            : { display: "none" }
        }
      ></div>
      <SEO title={loading ? "Loading vacancy..." : vacancyTitle} />
      <div className="main-container main-container--single">
        {!vacancy ||
          vacancy == [] ||
          (!vacancy.Description && (
            <div className="page__header-container page__header-container--vacancies-landing page__header-container--vacancies-landing-loading">
              <div className="container-fluid single-loading">
                <div
                  className="row justify-content-center page__header page__header--landing vacancies-landing__header page__header--vacancies-landing"
                  id="listing-top-single-loading"
                >
                  <div className="col-12 col-lg-8">
                    <>
                      <h1>Loading vacancy...</h1>
                      <p className="small-padding">
                        Please wait while we load the vacancy that you
                        requested.
                      </p>
                      {loadingText && <p>{loadingText}</p>}
                    </>
                  </div>
                </div>
              </div>
            </div>
          ))}

        {vacancy && vacancy != [] && vacancy.Description && (
          <div>
            <div id="ApplicationScrollTarget"></div>
            {!showApplication ? (
              <>
                {vacancy.PostingDate &&
                  vacancy.ExpiryDate &&
                  CheckJobHasNotExpired(vacancy.ExpiryDate) && (
                    <script
                      type="application/ld+json"
                      dangerouslySetInnerHTML={{
                        __html: JSON.stringify(
                          generateGoogleJobsSchema(vacancy)
                        ),
                      }}
                    />
                  )}
                <div className="container">
                  <div className="page-main-container-wrap">
                    <div className="row">
                      <div className="col-md-12 title-container">
                        <>
                          {!vacancy ||
                            vacancy == [] ||
                            (!vacancy.Description && (
                              <h1 className="vf-jobtitle">
                                Loading vacancy...
                              </h1>
                            ))}
                          {vacancy && vacancy != [] && vacancy.Description && (
                            <>
                              <h1 className="vf-jobtitle">{vacancyTitle}</h1>
                              <h2>
                                <span className="vf-job-info-value vf-Location">
                                  {vacancy.Location}
                                </span>
                              </h2>
                            </>
                          )}
                        </>
                      </div>
                      <div className="content col-lg-8 col-md-6 col-sm-12">
                        <div className="vf-JobDescription">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: vacancy.Description,
                            }}
                          />
                        </div>
                        <div className="bottom-apply">
                          <div className="vf-buttons">
                            <a
                              target="_blank"
                              href={
                                vacancy.Link + "&action=ApplyNow&iframe=False"
                              }
                              className="vf-apply-button ui button btn btn-primary"
                            >
                              Apply now
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="sidebarContentBlock">
                          <div className="sidebarContentBlock_section color_one">
                            <div className="vf-map">
                              {vacancy.Location !== "Homebased" &&
                                vacancy.Location !== "Flexible" &&
                                vacancy.Latitude != "" &&
                                vacancy.Latitude != null &&
                                vacancy.Latitude != 0 && (
                                  <Map
                                    latitude={Number(vacancy.Latitude)}
                                    longitude={Number(vacancy.Longitude)}
                                    zoom={12}
                                    scrollWheelZoom={false}
                                    tooltipText={vacancy.JobTitle}
                                  />
                                )}
                            </div>
                            <div id="info">
                              <div className="job-title-meta">
                                <strong>Reference:</strong>
                                <span>{vacancy.AdvertReference}</span>
                              </div>
                              <div className="job-title-meta">
                                <strong>Job Type:</strong>
                                <span>
                                  {vacancy.JobType +
                                    " - " +
                                    vacancy.JobTypeTime}
                                </span>
                              </div>
                              <div className="job-title-meta">
                                <strong>Salary:</strong>
                                <span>
                                  {calculateSalary(
                                    vacancy.Currency,
                                    vacancy.SalaryFrom,
                                    vacancy.SalaryTo,
                                    vacancy.SalaryType,
                                    vacancy.SalaryPeriod
                                  )}
                                </span>
                              </div>
                              <div className="job-title-meta">
                                <strong>Expiry Date:</strong>
                                <span>
                                  {FormatDateTime(vacancy.ExpiryDate)}
                                </span>
                              </div>
                              <div className="job-title-meta">
                                <strong>Location:</strong>
                                <span>{vacancy.Location}</span>
                              </div>
                              <div className="job-title-meta">
                                <strong>Benefits:</strong>
                                <span>{vacancy.Benefits}</span>
                              </div>


                              {vacancy.Attachments &&
                              vacancy.Attachments != [] &&
                              vacancy.Attachments.length >= 0 ? (
                                <div className="vf-Attachments">
                                  <strong>Attachments:</strong>
                                  <br></br>
                                  {vacancy.Attachments &&
                                    vacancy.Attachments.map((attachment, i) => (
                                      <li
                                        key={i}
                                        id={attachment.S3Name}
                                        className="row"
                                      >
                                        <a
                                          className="btn-filedownload vf-row"
                                          href={attachment.Location}
                                          target="_blank"
                                          rel="nofollow noopener noreferrer"
                                        >
                                          <span className="details">
                                            <span className="far file outline icon"></span>
                                            <span className="filename">
                                              {attachment.FileName}
                                            </span>
                                          </span>
                                        </a>
                                      </li>
                                    ))}
                                </div>
                              ) : null}
                            </div>

                            <div className="bottom-apply">
                              <div className="vf-buttons">
                                <div className="vf-ApplyButton-bottom">
                                  <a
                                    target="_blank"
                                    href={
                                      vacancy.Link +
                                      "&action=ApplyNow&iframe=False"
                                    }
                                    className="vf-apply-button ui button btn btn-primary"
                                  >
                                    Apply now
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="sidebarContentBlock_section color_two">
                            <div className="social block">
                              <h3>Not the right role for you?</h3>
                              <p>Share it with someone you know:</p>
                              <div className="vf-JobActions">
                                <div id="share-icons">
                                  <div className="vf-SocialButtons">
                                    <a
                                      data-sharelinkend=""
                                      href={
                                        "https://www.facebook.com/sharer/sharer.php?u=" +
                                        "https://devonshiregroupcareers.co.uk/vacancies/vacancy/" +
                                        base64url(vacancy.Id)
                                      }
                                      target="_blank"
                                      rel="nofollow"
                                      className="vf-facebook-sharing-button vf-shareButton vf-activate-sharing-button"
                                    >
                                      <img
                                        src="https://cdn1.accropress.com/Woodland%20Trust/images/facebook-dark.svg"
                                        alt="facebook"
                                      />
                                    </a>
                                    <a
                                      href={
                                        "https://twitter.com/intent/tweet?text=" +
                                        vacancyTitle +
                                        " - " +
                                        "https://devonshiregroupcareers.co.uk/vacancies/vacancy/" +
                                        base64url(vacancy.Id)
                                      }
                                      target="_blank"
                                      rel="nofollow"
                                      className=" vf-twitter-sharing-button vf-shareButton vf-activate-sharing-button"
                                    >
                                      <img
                                        src="https://cdn1.accropress.com/Woodland%20Trust/images/twitter-dark.svg"
                                        alt="Twitter"
                                      />
                                    </a>
                                    <a
                                      href={
                                        "https://www.linkedin.com/shareArticle?mini=true&url=" +
                                        "https://devonshiregroupcareers.co.uk/vacancies/vacancy/" +
                                        base64url(vacancy.Id)
                                      }
                                      target="_blank"
                                      rel="nofollow"
                                      className=" vf-linkedin-sharing-button vf-shareButton vf-activate-sharing-button"
                                    >
                                      <img
                                        src="https://cdn1.accropress.com/Woodland%20Trust/images/linkedin-icon.svg"
                                        alt="LinkedIn"
                                      />
                                    </a>
                                    <a
                                      href={
                                        "mailto:?&subject=Check out this vacany&body=Check out this " +
                                        vacancy.JobTitle +
                                        " vacancy " +
                                        " - https://devonshiregroupcareers.co.uk/vacancies/vacancy/" +
                                        base64url(vacancy.Id)
                                      }
                                      target="_blank"
                                      rel="nofollow"
                                      className=" vf-email-sharing-button vf-shareButton vf-activate-sharing-button"
                                    >
                                      <img
                                        src="https://cdn1.accropress.com/Woodland%20Trust/images/newsletter-icon.svg"
                                        alt="Email"
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <a href="/devonshire-group/" target="_blank">
                                Or click here to find out more about the
                                Devonshire Group
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="vacancy-application-wrapper">
                <div className="vacancy-application-topbar vacancy-application-topbar---standard-app">
                  <div className="container">
                    <div className="row">
                      <div className="col-12 col-lg-6 job-title-app">
                        <span>{vacancyTitle + " application"}</span>
                      </div>
                      <div className="col-12 col-lg-6 back-to-description">
                        <a
                          className="btn--action"
                          onClick={() => {
                            activateApplicationForm(false)
                          }}
                        >
                          Go back to the description
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="vf_iFrame_wrapper">
                  <div className="container">
                    <iframe
                      id="vf_iFrame_application_form"
                      src={
                        vacancy.Link + "&action=ApplyNow&HideCareersLink=true"
                      }
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </Layout>
  )
}

export default SmartfeedIndividual

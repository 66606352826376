import React from "react"
import { Router } from "@reach/router"

import SmartFeedIndividual from "../modules/SmartFeedIndividual"
import SmartFeedList from "../modules/SmartFeedList"
import SmartFeedNotFound from "../modules/SmartFeedNotFound"

const Vacancies = () => {
  return (
    <Router>
      <SmartFeedNotFound default />
      <SmartFeedIndividual path="/vacancies/vacancy/*" />
      <SmartFeedList path="/vacancies" />
    </Router>
  )
}

export default Vacancies

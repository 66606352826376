import React, { useState } from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"

import SmartFeedFiltersComponent from "../components/SmartFeedWithFilters_WT"

require("es6-promise").polyfill()

export default function VacanciesListPage(props) {
  const [vacancyLoading, setVacancyLoading] = useState(true)

  return (
    <Layout {...props}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Devonshire Group Careers</title>
        <meta
          name="description"
          content="Find your career at The Devonshire Group today."
        />
      </Helmet>

      <section className="app-content">
        <div
          className="main-banner"
          style={{
            backgroundRepeat: "no-repeat",
            backgroundImage: `
              url("https://cdn2.accropress.com/content-uploads/3d6c7a45-b41d-47fd-8bff-01ee8463584e/6d2f19dc-e06c-4883-b522-a2f90a837ff8/D7v9tMfrTw6Iq8qvYASr_derbyshire_estate_main.jpg")`,
          }}
        ></div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="VF_filter_form">
                <div className="searchBlock">
                  <h1>DEVONSHIRE GROUP VACANCIES</h1>
                  <div className="main-intro">
                    <p>
                      <a
                        href="/devonshire-group/"
                        className="button btn btn-primary-inverted btn-small"
                      >
                        VIEW INFORMATION ABOUT THE DEVONSHIRE GROUP
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SmartFeedFiltersComponent
          setVacancyLoading={setVacancyLoading}
          vacancyLoading={vacancyLoading}
          apiKey={"KveXyBB8IM6CDLBqJxwd16Qd6u7LWisyQeg1buu3SrQ"}
          group={false}
          gdprKey=""
          CompanyGroupName="Devonshire Group"
          VacancyPageBaseURL="https://devonshiregroupcareers.co.uk/vacancies/vacancy/"
          ReturnToSearchURL="https://devonshiregroupcareers.co.uk/vacancies/"
          contentDataData={{}}
        />
      </section>
    </Layout>
  )
}
